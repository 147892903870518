function showAlert(message) {
    var errors = `
    <ul class="flex flex-wrap gap-x-4 items-center">
      <li>${message}</li>
    </ul>
      `;
    $('#notification-modal .modal-body').html(errors);
    $('#notification-modal-button').trigger('click');
}

$(() => {
    function checkRequiredFields() {
        const account = $('#account').val();
        // const currency = $('#currency').val();
        const fcAmount = $('#fc_amount').val();
        // const rate = $('#rate').val();
        const usdAmount = $('#usd_amount').val();

        if (account && (fcAmount || usdAmount)) {
            $('.add-btn').prop('disabled', false);
        } else {
            $('.add-btn').prop('disabled', true);
        }
    }

    function resetForm() {
        $('.voucher-detail').val('');
        $("#voucher_type").val("Debit");
        $("#account").val("");
        $("#category").val("Normal JV");
        $("#serial_no").val(0);
        $('.add-btn').text('Add')
        checkRequiredFields();
    }

    function computeDebitCredit() {
        const totals = $('#journal-vouchers-table .amount_usd').toArray()
            .reduce(function (pv, cv) {
                const el = $(cv);
                const type = el.parent().children('.voucher_type').text();
                if (type == 'Debit') {
                    const val = parseFloat(el.text()) || 0;
                    pv[0] = pv[0] + val;
                    pv[2] = pv[2] - val;
                };
                if (type == 'Credit') {
                    const val = parseFloat(el.text()) || 0;
                    pv[1] = pv[1] + val;
                    pv[2] = pv[2] + val;
                };
                return pv;
            }, [0, 0, 0]);
        $("#total-debit").val(totals[0].toFixed(2));
        $("#total-credit").val(totals[1].toFixed(2));
        $("#balance").val(totals[2].toFixed(2));
    }

    checkRequiredFields();
    $('#voucher-details-form').on('change', 'input, select', function () {
        checkRequiredFields();
        $('#approval-link').addClass('hidden');
    });
    $('button.add-btn').on('click', function () {
        let serialNo = $("#serial_no").val();
        let row;
        // Serial No 0 is for new Entry
        console.log(serialNo);
        if (serialNo == 0) {
            serialNo = $('#journal-vouchers-table tbody tr').length + 1;
            row = $('<tr class="text-center" id="serial_no_' + serialNo + '"></tr>'); // Add 'new-row' class to new rows
            $('#journal-vouchers-table tbody').append(row);
        }
        else {
            row = $("#journal-vouchers-table #serial_no_" + serialNo);
            row.empty();
        }
        row.append($('<td></td>').text(serialNo));
        row.append($('<td class="voucher_type"></td>').text($('#voucher_type').val()));
        row.append($('<td data-value="' + $('#account').val() + '"></td>').text($('#account option:selected').text()));
        row.append($('<td></td>').text($('input[name="subac"]').val()));
        row.append($('<td></td>').text($('#category').val()));
        row.append($('<td data-value="' + $('#currency').val() + '"></td>').text($('#currency option:selected').text()));
        row.append($('<td></td>').text($('input[name="amountinfc"]').val()));
        row.append($('<td></td>').text($('input[name="rate"]').val()));
        row.append($('<td></td>').text(''));
        row.append($('<td class="amount_usd"></td>').text($('input[name="amountusd"]').val()));
        row.append($('<td></td>').text($('input[name="remarks"]').val()));
        row.append($('<td></td>').text($('input[name="documentno"]').val()));
        row.append($('<td><button class="btn btn-icon btn_outlined btn_secondary uppercase my-2 edit-btn" type="button"><span class="la la-pen-fancy"></span></button></td>'));
        row.append($('<td class="account-no hidden"></td>').text($("#account").val()));

        computeDebitCredit();

        resetForm();
    });
    $('#journal_vouchers_form').one('submit', function () {
        $('#journal-vouchers-table tbody tr').each(function () {
            const voucher_type = $(this).find('td:nth-child(2)').text();
            const account = $(this).find('td').eq(2).attr('data-value');
            const sub_account = $(this).find('td:nth-child(4)').text();
            const category = $(this).find('td:nth-child(5)').text();
            const currency = $(this).find('td').eq(5).attr('data-value');
            const amount_in_fc = $(this).find('td:nth-child(7)').text();
            const rate = $(this).find('td:nth-child(8)').text();
            const amount_in_lc = $(this).find('td:nth-child(10)').text();
            const remarks = $(this).find('td:nth-child(11)').text();
            const doc_no = $(this).find('td:nth-child(12)').text();
            // if (typeof account == 'undefined') {
            //     return;
            // }
            if (account == "Please Select" || currency == "Please Select") {
                return;
            }
            let inputs = '';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][voucher_type]" value="' + voucher_type + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][account_id]" value="' + account + '">';
            // inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][sub_account]" value="' + sub_account + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][category]" value="' + category + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][fc_amount]" value="' + amount_in_fc + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][rate]" value="' + rate + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][usd_amount]" value="' + amount_in_lc + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][remarks]" value="' + remarks + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][document_no]" value="' + doc_no + '">';
            inputs += '<input type="hidden" name="journal_voucher[journal_voucher_details_attributes][][currency_id]" value="' + currency + '">';
            $(inputs).appendTo('#journal_vouchers_form');
        });
    });
    $(document).on('change', '#account', function () {
        $("#rate").val(1.0)
        var accountId = $(this).val();
        handleCurrency(accountId);
    })

    $(document).on('change', '#currency', function () {
        var accountId = $("#account").val();
        var currencyId = $(this).val();
        $.ajax({
            url: window.location.pathname,
            type: "GET",
            dataType: "json",
            data: { entity_account: accountId, currency_id: currencyId },
            error: function (xhr, status, error) {
                console.error("AJAX Error: " + status + error);
            },
            success: function (response) {
                $("#rate").val(response.rate).trigger('change');
            }
        });
    })

    $('#usd_amount').on('change', function () {
        var usd_amount = $(this).val();
        var rate = $("#rate").val();
        if (usd_amount && rate) {
            var amount_in_fc = (usd_amount / rate).toFixed(2);
            $("#fc_amount").val(amount_in_fc);
        } else {
            $("#fc_amount").val(0.00);
        }
    });
    $('#fc_amount').on('change', function () {
        var fc_amount = $(this).val();
        var rate = $("#rate").val();
        if (fc_amount && rate) {
            var usd_amount = (fc_amount * rate).toFixed(2);
            $("#usd_amount").val(usd_amount);
        } else {
            $("#usd_amount").val(0.00);
        }
    });
    $("#rate").on('change', function () {
        let fc_amount = $("#fc_amount").val();
        let usd_amount = $("#usd_amount").val();
        let rate = $(this).val();
        if (fc_amount) {
            usd_amount = (fc_amount * rate).toFixed(2);
            $("#usd_amount").val(usd_amount);
        } else if (usd_amount) {
            fc_amount = (usd_amount / rate).toFixed(2);
            $("#fc_amount").val(fc_amount);
        } else {
            $("#fc_amount , #usd_amount").val(0)
        }
    });

    $('#jv_post_date').on('change', function () {
        $('#journal_voucher_post_date').val(this.value);
    });
    $('#journal_voucher_post_date').val($('#jv_post_date').val());

    function handleCurrency(accountId, currencyCode = "") {
        $.ajax({
            url: window.location.pathname,
            type: "GET",
            dataType: "json",
            data: { entity_account: accountId },
            error: function (xhr, status, error) {
                console.error("AJAX Error: " + status + error);
            },
            success: function (response) {
                const currencies = response.currencies;
                const currencyDropdown = $('#currency');
                currencyDropdown.empty().append('<option>Please Select</option>');
                let selectedCode = $('#account option:selected').text().split('|').pop().trim();
                if (!selectedCode || selectedCode === 'Please Select') selectedCode = 'USD'
                currencies.forEach(function (currency) {
                    const option = $('<option>', {
                        value: currency.id,
                        text: currency.code
                    });
                    if (currency.code === selectedCode) {
                        option.attr('selected', 'selected');
                    }
                    currencyDropdown.append(option);
                });
                if (currencyCode) {
                    currencyDropdown.val(currencyCode)
                }
                if (!!currencyDropdown.val()) currencyDropdown.trigger('change');
            }
        });
    }
    $(document).on('click', '.edit-btn', function () {
        const serialNo = $(this).closest('tr').find('td:nth-child(1)').text();
        const type = $(this).closest('tr').find('td:nth-child(2)').text();
        const accountId = $(this).closest('tr').find('td:nth-child(14)').text();
        const category = $(this).closest('tr').find('td:nth-child(5)').text();
        const currency = $(this).closest('tr').find('td:nth-child(6)').attr('data-value');
        const fcDebit = $(this).closest('tr').find('td:nth-child(7)').text();
        const rate = $(this).closest('tr').find('td:nth-child(8)').text();
        const fcCredit = $(this).closest('tr').find('td:nth-child(10)').text();
        const remarks = $(this).closest('tr').find('td:nth-child(11)').text();
        const DocNo = $(this).closest('tr').find('td:nth-child(11)').text();

        $('#voucher_type').val(type)
        $('#category').val(category)
        $('#remarks').val(remarks)
        $("#doc-no").val(DocNo)
        $("#fc_amount").val(fcDebit)
        $("#rate").val(rate)
        $("#usd_amount").val(fcCredit)
        $("#serial_no").val(serialNo)
        $('.add-btn').text('Update');
        $('#account').val(accountId);
        handleCurrency(accountId, currency);
    });

})

document.addEventListener('DOMContentLoaded', function () {
    const submitLink = document.getElementById('approval-link');
    if (!submitLink) return;
    submitLink.addEventListener('click', function (event) {
        event.stopPropagation();
        event.preventDefault();

        const comment = document.getElementById('journal_voucher_audit_comment').value;

        if (!comment) {
            showAlert('Remark is MISSING!');
            return;
        }

        if (!confirm('Are you sure?')) return;
        console.log('HHHHH');

        const url = new URL(submitLink.href);
        url.searchParams.set('audit_comment', comment);

        // Redirect to the updated URL
        window.location.href = url.toString();
    });
});
